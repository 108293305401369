@charset "UTF-8";

@import "./_setting";
@import "./_mixin";
@import "./module/_btn";
@import "./module/_title";
@import "./module/_other";

// header 背景
.l-header .glnv li.top {
  background-color: #fff;
}
// スクロールバー
.scrollbar {
  position: absolute;
  top: 85%;
  left: -140px;
  @include breakpoint($maxWidth) {
    top: 83.5%;
    left: -130px;
  }
  @include bpPc {
    left: -128px;
  }
  @include bpSp {
    display: none;
  }
  .scroll-text {
    position: absolute;
    top: -100px;
    left: 80px;
    transform: rotate(-90deg);
    @include f-size(18);
  }
  .bar-box {
    position: absolute;
    top: 60px;
    left: 0px;
    width: 203px;
    transform: rotate(90deg);
    color: #000;
    @include breakpoint($maxWidth) {
      top: 35px;
      left: 16px;
      width: 171px;
    }
    .scroll-text01 {
      line-height: 1;
      text-transform: uppercase;
    }
    .scroll-line {
      background: #E4E4E4;
      height: 1px;
      overflow: hidden;
      position: relative;
      .scroll-line-inner {
        position: absolute;
        background-color: #000;
        height: 1px;
        left: 0;
        top: 0;
        animation: scrollmove 3s cubic-bezier(0.25, 1, 0.25, 1) infinite;
      }
    }
  }
}
@keyframes scrollmove {
  0% {
    left: 0;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    width:10px;
  }
  20% {
    width: 30px;
  }
  80% {
    width: 30px;
  }
  to {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    left: 100%;
    width: 0;
  }
}

// ご挨拶
.top-greeting {
  padding-top: 120px;
  padding-bottom: 130px;
  background-image: url(../img/top/bg_sec01.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  text-align: center;
  @include bpPcs {
    padding-top: 70px;
    padding-bottom: 80px;
    background: none;
  }
  @include bpSp {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  .about-title {
    padding-bottom: 50px;
    @include f-size(28);
    line-height: 1.6em;
    @include bpSp {
      padding-bottom: 30px;
      @include f-size(22);
    }
  }
  .about-text {
    padding-bottom: 60px;
    line-height: 2em;
    @include bpSp {
      padding-bottom: 40px;
      @include f-size(12);
    }
  }
  .m-btn2 {
    width: 275px;
    @include bpSp {
      width: 230px;
    }
  }
}

// 新着情報
.top-news {
  position: relative;
  .news-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 300px;
    background-color: $color2;
    z-index: 1;
    @include bpPcs {
      padding-bottom: 200px;
    }
    @include bpSp {
      padding-bottom: 150px;
    }
  }
  .news-wrapper {
    position: relative;
    width: 100%;
    max-width: 1415px;
    margin-left: auto;
    padding-top: 80px;
    z-index: 1;
    @include bpPcs {
      padding-top: 50px;
    }
    @include bpSp {
      padding-top: 30px;
      padding-right: $baseWidthPadding;
      padding-left: $baseWidthPadding;
    }
    .m-title1 {
      padding-bottom: 25px;
      @include bpPc {
        padding-right: 20px;
        padding-left: 20px;
      }
      @include bpPcs {
        padding-bottom: 20px;
      }
      @include bpSp {
        padding-right: 2%;
        padding-left: 2%;
      }
    }
    .top-news-slider {
      li {
        a {
          display: block;
          width: 100%;
          max-width: 318px;
          @include bpPc {
            width: 91.5%;
            max-width: 300px;
            margin-right: auto;
            margin-left: auto;
          }
          &:hover {
            text-decoration: none;
          }
        }
        .image {
          text-align: center;
          img {
            width: 318px;
            height: 217px;
            object-fit: cover;
            font-family: 'object-fit: cover;'
          }
        }
        .contents-header {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          padding-top: 20px;
          @include bpSp {
            padding-top: 10px;
          }
          .time {
            display: inline-block;
            @include f-size(14);
            @include bpSp {
              width: 100%;
              @include f-size(13);
              padding-bottom: 10px;
            }
          }
          .category-block {
            display: flex;
            .new {
              display: inline-block;
              margin-right: 5px;
              padding: 1px 10px;
              background-color: $color1;
              border: 1px solid $color1;
              color: #fff;
              @include f-size(12);
              @include bpSp {
                @include f-size(10);
              }
            }
            .l-space {
              display: none;
            }
            .category {
              padding: 1px 10px;
              border: 1px solid $color1;
              color: $color1;
              @include f-size(12);
              @include bpSp {
                @include f-size(10);
              }
            }
          }
        }
        .title {
          padding-top: 15px;
        }
      }

      // スライダー 設定
      .slick-prev,
      .slick-next {
        top: -35px;
        width: 30px;
        height: 32px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .slick-prev {
        left: auto;
        right: 90px;
        @include bpPcs {
          right: 60px;
        }
        &::before {
          background-image: url(../img/img_prew.png);
        }
      }
      .slick-next {
        right: 35px;
        @include bpPcs {
          right: 15px;
        }
        &::before {
          background-image: url(../img/img_next.png);
        }
      }
      .slick-track {
        margin-left: 0;
        li {
          // width: 100%!important;
          // max-width: 318px;
        }
      }
    }
  }
  .news-btn {
    text-align: right;
    .m-btn3 {
      display: inline-block;
      margin-top: 40px;
    }
  }
}

// 宇野港土地について
.top-about {
  padding-top: 170px;
  padding-bottom: 180px;
  @include bpPcs {
    padding-top: 35px;
    padding-bottom: 80px;
  }
  @include bpSp {
    padding-top: 0;
    padding-bottom: 45px;
    padding-right: $baseWidthPadding;
    padding-left: $baseWidthPadding;
  }
  .about-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 1350px;
    margin-right: auto;
    margin-left: auto;
    @include bpPcs {
      flex-wrap: wrap;
    }
    .about-image {
      position: relative;
      top: -50px;
      left: 30px;
      width: 605px;
      z-index: 2;
      @include bpPcs {
        top: 50px;
        left: 0;
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
    }
    .about-text {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 745px;
      background-color: $color1;
      color: #fff;
      z-index: 1;
      @include bpPcs {
        width: 100%;
        padding: 12.5% 5% 7.5%;
      }
      @include bpSp {
        padding: 17% 7% 8%;
      }
      &-inner {
        position: relative;
        width: 100%;
        max-width: 425px;
        margin-right: auto;
        margin-left: auto;
        &::after {
          content: "";
          position: absolute;
          top: -20px;
          right: -35px;
          transform: translate(50%,-50%);
          background-image: url(../img/top/obj_about01.png);
          background-repeat: no-repeat;
          background-size: 100%;
          width: 120px;
          height: 69px;
          @include bpPc {
            top: 20%;
            right: 15%;
            width: 20%;
          }
          @include bpSp {
            top: 25%;
            right: 10%;
            width: 20%;
          }
        }
      }
      .m-title1 {
        padding-bottom: 25px;
      }
      .about-btn {
        margin-top: 15px;
        text-align: right;
      }
    }
  }
}

// 事業内容
.top-business {
  .business-bg {
    position: relative;
    background-image: url(../img/top/bg_sec02.jpg);
    background-repeat: no-repeat;
    background-position: center;
    width: 1838px;
    height: 1038px;
    margin-right: auto;
    margin-left: auto;
    @include breakpoint($maxWidth) {
      width: 100%;
    }
    @include bpPcs {
      height: auto;
      padding-bottom: 50%;
      background-size: cover;
    }
    @include bpSp {
      padding-bottom: 90%;
      background-image: url(../img/top/bg_sec02_sp.jpg);
    }
    @include bpSps {
      padding-bottom: 100%;
    }
    .parts {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      @include bpSp {
        display: none;
      }
      &.top {
        top: 0;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 14px;
          height: 14px;
          border-top: 1px solid #555;
          border-right: 1px solid #555;
        }
      }
      &.bottom {
        bottom: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
        }
        &::before {
          bottom: 0;
          left: 0;
          border-bottom: 1px solid #555;
          border-left: 1px solid #555;
        }
        &::after {
          bottom: 0;
          right: 0;
          border-bottom: 1px solid #555;
          border-right: 1px solid #555;
        }
      }
    }
  }
  .m-wrapper {
    position: relative;
    height: inherit;
    @include bpPcs {
      width: 100%;
      padding: 0;
      height: auto;
      padding-bottom: inherit;
    }
    @include bpSp {
      max-width: $baseWidth;
      padding: 0 $baseWidthPadding;
    }
    .m-title1 {
      padding-top: 30px;
      @include bpPcs {
        padding-top: 20px;
      }
      @include bpSp {
        padding-top: 0;
        padding-left: 4%;
      }
    }
  }
  .pc-link {
    @include bpSp {
      display: none;
    }
  }
  .sp-link {
    display: none;
    @include bpSp {
      display: block;
      max-width: $baseWidth;
      margin-top: 20px;
      padding: 0 $baseWidthPadding;
    }
  }
  .business-link {
    position: absolute;
    @include f-size(16);
    @include bpPcs {
      font-size: 1.6vw;
    }
    @include bpSp {
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      display: block;
      @include f-size(15);
      text-align: left;
    }
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translate(50%,-50%);
      width: 24px;
      height: 24px;
      background-image: url(../img/img_next.png);
      background-repeat: no-repeat;
      background-size: contain;
      @include bpPcs {
        width: 2.3vw;
        height: 2.3vw;
      }
      @include bpSp {
        right: $baseWidthPadding;
        width: 20px;
        height: 20px;
      }
    }
    &.link1 {
      top: 236px;
      right: 170px;
      @include bpPcs {
        top: 22.5vw;
        right: 12vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
    &.link2 {
      top: 318px;
      right: 88px;
      @include bpPcs {
        top: 30vw;
        right: 6vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
    &.link3 {
      top: 376px;
      right: 88px;
      @include bpPcs {
        top: 36vw;
        right: 5.5vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
    &.link4 {
      top: 497px;
      right: 88px;
      @include bpPcs {
        top: 47vw;
        right: 5.5vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
    &.link5 {
      top: 555px;
      right: 88px;
      @include bpPcs {
        top: 52.7vw;
        right: 5.5vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
    &.link6 {
      top: 436px;
      right: 88px;
      @include bpPcs {
        top: 41.5vw;
        right: 5.5vw;
      }
      @include bpSp {
        top: 0;
        right: 0;
      }
    }
  }
}

.business-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1350px;
  margin-top: -100px;
  margin-right: auto;
  margin-left: auto;
  @include bpPcs {
    margin-top: -50px;
  }
  @include bpSp {
    max-width: $baseWidth;
    margin-top: 50px;
    padding: 0 $baseWidthPadding;
  }
  .business-item {
    position: relative;
    width: 25%;
    max-width: 320px;
    margin-right: 20px;
    margin-bottom: 70px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    @include bpPcs {
      width: 28%;
      margin-right: 15px;
      margin-bottom: 40px;
      &:nth-child(4n) {
        margin-right: 15px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @include bpSp {
      width: 47.5%;
      margin-bottom: 30px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(odd) {
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    &-image {
      padding-bottom: 30px;
      @include bpPcs {
        padding-bottom: 20px;
      }
      @include bpSp {
        padding-bottom: 15px;
      }
    }
    &-number {
      padding-bottom: 15px;
      font-family: $Libre;
      @include f-size(20);
      line-height: 1;
      @include bpPcs {
        padding-bottom: 5px;
        @include f-size(18);
      }
      @include bpSp {
        @include f-size(15);

      }
    }
    &-title {
      padding-bottom: 15px;
      @include f-size(20);
      @include bpPcs {
        padding-bottom: 10px;
        @include f-size(18);
      }
      @include bpSp {
        @include f-size(15);
      }
    }
    &-text {
      padding-bottom: 40px;
      @include bpPcs {
        padding-bottom: 30px;
      }
    }
    .business-btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.business-bottombtn {
  text-align: center;
  .m-btn2 {
    width: 275px;
    text-align: center;
    @include bpSp {
      width: 200px;
    }
  }
}

// ピックアップ
.top-pickup {
  padding-top: 120px;
  @include bpPcs {
    padding-top: 70px;
  }
  @include bpSp {
    max-width: $baseWidth;
    padding: 50px $baseWidthPadding 0;
  }
  .pickup-wrapper {
    position: relative;
    width: 100%;
    max-width: 1145px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
    padding-bottom: 70px;
    background-image: url(../img/bg_pattern1.jpg);
    @include bpPcs {
      padding-top: 45px;
      padding-bottom: 45px;
    }
    .brackets {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      &.before {
        left: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
        }
        &::before {
          top: 0;
          left: 0;
          border-top: 1px solid #555;
          border-left: 1px solid #555;
        }
        &::after {
          bottom: 0;
          left: 0;
          border-bottom: 1px solid #555;
          border-left: 1px solid #555;
        }
      }
      &.after {
        right: 0;
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 14px;
          height: 14px;
        }
        &::before {
          top: 0;
          right: 0;
          border-top: 1px solid #555;
          border-right: 1px solid #555;
        }
        &::after {
          bottom: 0;
          right: 0;
          border-bottom: 1px solid #555;
          border-right: 1px solid #555;
        }
      }
    }
    .pickup-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 950px;
      margin-right: auto;
      margin-left: auto;
      z-index: 2;
      @include bpPcs {
      }
      .m-title1 {
        width: 22%;
        @include bpPcs {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
        }
      }
      .pickup-bnr {
        display: flex;
        justify-content: space-between;
        width: 78%;
        @include bpPcs {
          width: 90%;
          justify-content: center;
        }
        @include bpSp {
          flex-wrap: wrap;
        }
        a {
          display: block;
          width: 50%;
          text-align: center;
          @include bpPcs {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
          }
          @include bpSp {
            padding-right: 0;
            padding-left: 0;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

// リンク
.top-otherlink {
  padding-top: 90px;
  padding-bottom: 240px;
  @include bpPcs {
    padding-top: 50px;
    padding-bottom: 110px;
  }
  @include bpSp {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .otherlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 710px;
    margin-right: auto;
    margin-left: auto;
    li {
      display: flex;
      align-items: center;
      width: 50%;
      @include bpPcs {
        width: 51%;
        margin: 0 auto;
        padding-bottom: 40px;
        &:last-child {
          padding-bottom: 0;
        }
      }
      @include bpSps {
        width: 75%;
      }
      .other-icon {
        position: relative;
        width: 80px;
        height: 80px;
        padding: 30px;
        background-color: $color1;
        border-radius: 40px;
        @include bpPcs {
          width: 60px;
          height: 60px;
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: #fff;
          @include bpPcs {
            width: 50%;
          }
        }
      }
      .othertitle {
        padding-left: 20px;
        @include bpSp {
          padding-left: 10px;
        }
        &-en {
          color: $color1;
          @include f-size(28);
          line-height: 1.2em;
          @include bpPcs {
            @include f-size(24);
          }
          @include bpSp {
            @include f-size(20);
          }
        }
        &-ja {
          display: block;
          font-family: $Gothic;
          @include f-size(14);
          font-weight: 300;
          @include bpPcs {
            @include f-size(13);
          }
          @include bpSp {
            @include f-size(11);
          }
        }
      }
      .m-btn3 {
        margin-left: 35px;
        @include bpSp {
          margin-left: 15px;
        }
      }
    }
  }
}

